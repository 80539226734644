// Component Header
// @author: Vicente Illanes
// @version: 10.05.2022
// import React, { useState } from 'react';
import React from "react";
import "../../styles/spinner.css";
import "../../styles/quality.css";
import TreeDashboard from "./tree-dashboard";
import DashboardQuality from "./dashboard";
import { WSRequestGet } from "../../apis/dash_order";
import ReportQuality from "./report";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';


class Develop extends React.Component {
  state = {
    allLines: [],
    executeOrders: [],
    completedOrders: [],
    allForms: [],
    loadingLines: true,
    loadingForms: true,
    loadingOrders: true,
    activate_orders: {},
    forms: [],
    loadingForms: true,
    filter_tree: "",
    report_view: false,
    reportForms: []
  };

  async componentDidMount() {
    await this.getAllLines();
    await this.DashOrder_Get();
    await this.allForms();
  }

  /**
   * Obtiene las lineas de producción asociadas a las plantas
   */
  getAllLines = async () => {
    const url = URL_INIT;
    const controller = "api/v4/lineapp";
    const action = "lines";
    try {
      const response = await WSRequestGet(url, controller, action, {});
      this.setState({
        allLines: response.data,
        loadingLines: false
      });
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * Obtiene las ordenes de producción completas o en desarrollo
   * de las ultimas 24 horas
   */
  DashOrder_Get = async () => {
    const url = URL_INIT;
    const controller = "api/v4/lineapp";
    const action = "production_orders_quality";
    try {
      const response = await WSRequestGet(url, controller, action, {});
      this.DashOrder_Result(response.data);
    } catch (err) {
      console.log(err);
    }
  };


  /**
   * Obtiene todos los formularios de las ordenes de produccion
   * de las ultimas 24 horas
   */
  allForms = async () => {
    const url = URL_INIT;
    const controller = "api/v4/lineapp";
    const action = "forms_quality";
    try {
      const response = await WSRequestGet(url, controller, action, {});
      this.formsResult(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * Guarda las ordenes de produccion
   * @param {Object} data
   */
  DashOrder_Result = data => {
    this.setState({
      executeOrders: data.running,
      completedOrders: data.completed,
      loadingOrders: false
    });
    this.setActivateOrders(data.running);
  };

  /**
   * Guarda las ordenes de produccion
   * @param {Object} data
   */
  formsResult = data => {
    this.setState({
      forms: data.forms,
      reportForms: data.forms,
      loadingForms: false
    });
  };

  setActivateOrders = running_orders => {
    running_orders.map(order => {
      const current_order_by_line = this.state.activate_orders[order.line_id];
      if (current_order_by_line === undefined) {
        this.setOrderCurrent(order.line_id, order.id);
      }
    });
  };

  isActivate = (line_id, order_id) => {
    const current_order_by_line = this.state.activate_orders[line_id];

    if (current_order_by_line === undefined) {
      return "block";
    } else {
      return current_order_by_line === order_id ? "block" : "none";
    }
  };

  setOrderCurrent = (line_id, order_id) => {
    var copy = { ...this.state.activate_orders };
    copy[line_id] = order_id;
    this.setState({ activate_orders: copy });
  };

  allFormsByOrder = order_id => {
    return this.state.forms.filter(form => {
      return form.order_id === order_id;
    });
  };

  formsIncompletedByOrder = order_id => {
    return this.state.forms.filter(form => {
      return form.order_id === order_id && !form.is_ready;
    });
  };

  formsCompletedByOrder = order_id => {
    return this.state.forms.filter(form => {
      return form.order_id === order_id && form.is_ready;
    });
  };

  /* Fija el filtro de planta seccion o linea */
  selectFilterTree = node_id => {
    this.setState({ filter_tree: node_id });
  };

  /**
   * Segun filtros, obtiene el conjunto 
   * actual de Lineas de producción ue se deben mostrar en el dashboard
   * @returns {Array<Object>}
   */
  allLinesByFilter = () => {
    if (this.state.filter_tree === "") {
      return this.state.allLines;
    }
    const split_filter = this.state.filter_tree.split("/");
    const model_tag = split_filter[0];
    const model_id = split_filter[1];

    switch (model_tag) {
      case "line":
        return this.state.allLines.filter(line => {
          return model_id === String(line.id);
        });
      case "section":
        return this.state.allLines.filter(line => {
          return model_id === String(line.section_id);
        });
      case "plant":
        return this.state.allLines.filter(line => {
          return model_id === String(line.plant_id);
        });
    }
  };

  /**
   * Cambia la vista dashboard a reportería o viceversa
   */
  setView = () => {
    this.setState({ report_view: !this.state.report_view });
  };
  /**
   * Entrega titulo boton de cambio de vista segun estado
   */
  getTitleSetView = () => {
    if (this.state.report_view) {
      return "Dashboard";
    } else {
      return "Reportería";
    }
  };

  /**
   * Mantiene el estado de si se estan cargando o no los formularios
   * @param {boolean} b 
   */
  setLoadingForms = (b) => {
    this.setState({loadingForms: b});
  }

  /**
   * Guarda en el estado los formularios actuales del reporte
   * @param {Array<Object>} val
   */
  setCurrentReportForms = (val) => {
    this.setState({reportForms: val});
  }

  render() {
    return (
      <Grid container spacing={{ xs: 2, md: 3, md: 3, lg:3}} columnSpacing={{ xs: 0.5, sm: 0.5, md: 0.5 , lg: 0.5}} columns={{ xs: 12, sm: 12, md: 12, lg:12 }} sx={{margin: "2px"}}>
        <TreeDashboard
          title={this.props.system_online}
          button_description={this.props.button}
          selectFilterTree={this.selectFilterTree}
        />
        <Grid item xs={12} sm={12} md={10} lg={10}>
          <div>
            {this.state.loadingLines ? (
              <div className="spinner"></div>
            ) : (
                <Grid container spacing={{ xs: 1, md: 1, lg: 0.5}} columns={{ xs: 12, sm: 12, md: 12 , lg:12 }}>
                  <Grid item xs={12} sm={12} md={12}>
                  <Button
                    sx={{ mt: 2 }}
                    variant="outlined"
                    onClick={() => this.setView()}
                    title={this.getTitleSetView()}
                  >
                    {this.getTitleSetView()}
                  </Button>
                  </Grid>
                {this.state.report_view ? (
                   <ReportQuality  
                   setCurrentReportForms={this.setCurrentReportForms}
                   setLoadingForms={this.setLoadingForms}
                   forms={this.state.reportForms}
                   loadingForms={this.state.loadingForms}
                   /> 
                ) : (
                  <DashboardQuality
                    all_lines={this.allLinesByFilter()}
                    executeOrders={this.state.executeOrders}
                    completedOrders={this.state.completedOrders}
                    loadingOrders={this.state.loadingOrders}
                    forms={this.allFormsByOrder}
                    loadingForms={this.state.loadingForms}
                    setOrderCurrent={this.setOrderCurrent}
                    isActivate={this.isActivate}
                  />
                )}
               </Grid>
            )}
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default Develop;
